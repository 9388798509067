import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import P from '../components/paragraph'
import PageWrap from '../components/pageWrap'

const Patents = [
  '“Self-Cleaning System”, US 7549167. Issued June 16, 2009',
  '“SCIT - DNS: Critical Infrastructure Protection through Secure DNS Server Dynamic Updates”, US 7680955. Issued March 16, 2010',
  '“Single Use Server System”, US 7725531. Issued May 25, 2010',
  '“Self - cleansing secure DNS server”, US 8, 260, 963. Issued September 2, 2012.',
  '“Cache validating SCIT DNS server”, US 8, 356, 106. Issued January 1, 2013.',
  '“Data Alteration Prevention System”, US 8429219. Issued April 23, 2013 ',
]

const whitePapers = [
  {
    name: 'Cyber Security of Building Automation Controls Systems',
    link: 'http://old.scitlabs.com/images/pdf/auto-systems.pdf',
  },
  {
    name:
      'Major Security Breaches of the 21st Century: An Analysis of the Potential Impact of SCIT',
    link:
      'http://old.scitlabs.com/images/pdf/major-security-breaches-final.pdf',
  },
  {
    name:
      'Telos Corporation Ensures the Security Posture of Security Solutions from SCIT Labs',
    link: 'http://old.scitlabs.com/images/pdf/telos-assessment.pdf',
  },
  {
    name: 'SCIT Datasheet',
    link: 'http://old.scitlabs.com/images/pdf/SCIT-Datasheet-5.pdf',
  },
  {
    name: 'SCIT Concept of Operations',
    link: 'http://old.scitlabs.com/images/pdf/CONOP-for-SCIT.pdf',
  },
  {
    name:
      'Case Study: Continuous Monitoring, Compromised Server Handling and Forensics',
    link: 'http://old.scitlabs.com/images/pdf/CCF-CaseStudy.pdf',
  },
  {
    name: 'SCIT and Lockheed ClearSky Integration',
    link: 'http://old.scitlabs.com/images/pdf/SCIT_ClearSky-Integration2.pdf',
  },
]

const PublicationsPage = () => (
  <StaticQuery
    query={graphql`
      query {
        PublicationsPageImage: file(relativePath: { eq: "keyboard.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2880, maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <PageHeader image={data.PublicationsPageImage.childImageSharp}>
          Publications
        </PageHeader>
        <PageWrap>
          <h2>Publications</h2>
          <P>
            SCIT technology has been compared to other intrusion tolerance
            technologies in a 2011 analysis appearing in IEEE Security and
            Privacy. Document title: A Comparison of Intrusion-Tolerant System
            Architectures Authors: Quyen Nguyen and Arun Sood Publication Title:
            IEEE Security &amp; Privacy Volume: 9, Issue 4 Publication Year:
            2011, Page(s): 24 - 31
          </P>
        </PageWrap>
        <PageWrap>
          <P>
            <h2>Patents</h2>
            <ul>
              {Patents.map(patent => (
                <li key={patent}>{patent}</li>
              ))}
            </ul>
          </P>
        </PageWrap>
        <PageWrap>
          <P>
            <h2>White Papers</h2>
            <ul>
              {whitePapers.map(({name, link}) => (
                <li key={name}>
                  <a href={link} taget="_blank" rel="noopener">
                    {name}
                  </a>
                </li>
              ))}
            </ul>
          </P>
        </PageWrap>
      </Layout>
    )}
  />
)

export default PublicationsPage
